import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Times of Day',
  slug: '/times-of-day/',
  title: 'Times of Day',
}

export const query = graphql`
  query ClocksPageQuery {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "times-of-day.jpg" }) { ...ImageMain }
    results: postgres {
      clocks: allClocksList {
        ...Clock
      }
    }
  }
`

export default ({ data }) => {
  const { clocks } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Times of Day'
  const title = `${entity.singular} ${page}`
  const description = `
    Each time of day is unique and different from the others. Certain
    Fragrance Notes and Accords provide scents to match, and highlight, what
    makes any particular time of day so special.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: page,
      slug: links.clocks,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: title,
    things: clocks,
    slug: links.clocks,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={title}
        credit="times-of-day.jpg"
        fixed={imageMain}
      />

      <Paragraph>
        {description}
      </Paragraph>

      <List>
        {clocks.map(clock => (
          <ListItem key={clock.id}>
            <Link href={clock.slug}>
              {clock.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
