import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './paragraph.module.css'


const Paragraph = ({ children, id, leading }) => (
  <p
    className={classNames(style.paragraph, style[`leading_${leading}`])}
    id={id}
  >
    {children}
  </p>
)
Paragraph.defaultProps = {
  leading: 'copy',
}
Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  leading: PropTypes.oneOf(['copy', 'solid', 'title']),
}

export default Paragraph
